import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../../services/project.service';
import { Project } from '../../../models/project';
import { Page } from '../../../models/paging/page';
import { InspectionService } from '../../../services/inspection.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetService } from 'src/app/services/asset.service';
import { Asset } from 'src/app/models/asset';
import { ConfirmationService } from 'primeng/api';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-inspections',
  templateUrl: './inspections.component.html',
  styleUrls: ['./inspections.component.css']
})
export class InspectionsComponent implements OnInit {

  projects: Project[];

  filter: any = {
    projectId: null,
    canNumber: "",
    startDate: moment().add(-1, 'months').toDate(),
    endDate: moment().toDate(),
  }

  page = new Page();
  rows = Array<any>();
  filePage = new Page();
  fileRows = Array<any>();

  asset;


  constructor(private projectService: ProjectService, private reportableService: ReportableService,
    private inspectionService: InspectionService, public route: ActivatedRoute,
    private assetService: AssetService, private confirmationService: ConfirmationService,
    public modalService: ModalService, public router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.filePage.pageNumber = 0;
    this.filePage.size = 10;
    this.page.sortOrder = 'desc';
    this.page.sortBy = "InspectedAt";
    this.asset = new Asset();
  }

  ngOnInit() {
    this.projectService.getAll().then((projects) => {
      this.projects = projects;
      //console.log(projects);

      this.route.params.subscribe(params => {

        console.log(params.id);

        if (params.id != null && params.id != 'undefined' && params.id != undefined) {
          console.log(params);

          this.assetService.get(params.id).then((asset) => {
            this.asset = asset;
            this.filter.canNumber = asset.canNumber;
            this.filter.startDate = moment().add(-10, 'years').toDate();
            this.loadTable(this.page);
          });
        }
        else {
          this.loadTable(this.page);
        }
      });
    });
  }

  delete(inspection) {
    console.log("created popup");
    let inspDetails = 'CAN #: ' + inspection.canNumber + '<br/>';
    inspDetails += 'Inspected At: ' + inspection.inspectedAt_Formatted + '<br/>';
    inspDetails += 'Next Inspection Date: ' + inspection.nextInspectionDate_Formatted + '<br/>';
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this inspection?</br><br/>' + inspDetails,
      key: 'inspectionDelete',
      accept: () => {
        this.inspectionService.delete(inspection.id).then(() => {
          this.loadTable(this.page);
        });
      }
    });
  }

  edit(row) {
    this.router.navigate(['reports/inspections/edit'], { queryParams: { id: row.id } });
  }

  downloadReport(id, type) {
    this.reportableService.getROTE(id, type).then((data) => {
      importedSaveAs(data, 'Inspection Report.pdf');
    });
  }
  downloadChecklistReport(id) {
    this.reportableService.getChecklist(id).then((data) => {
      importedSaveAs(data, 'Inspection Checklist.pdf');
    });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.inspectionService.getResults(this.page, this.filter.startDate, this.filter.endDate, this.filter.projectId, this.filter.canNumber).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;

      console.log(this.rows);
    });
  }
  exportToExcel() {
    this.inspectionService.getExcelResults(this.page, this.filter.startDate, this.filter.endDate, this.filter.projectId, this.filter.canNumber).then((data) => {
      importedSaveAs(data, 'Inspection Report.xlsx');

    });
  }

  openModel(id) {
    this.modalService.open(id);
  }

  viewFiles(row) {
    this.openModel('popup-inspection-files');

    this.loadFilesTable(this.filePage, row.fileLocationId )
    

  }
  loadFilesTable(page: Page, fileLocationId) {
    if (fileLocationId == null) {
      return;
    }
    this.inspectionService.getFiles(page, fileLocationId).then((pagedData) => {
      this.fileRows = pagedData.data;
      this.filePage.totalElements = this.fileRows.length;
      this.filePage.pageNumber = 0;
      this.filePage.size = this.fileRows.length;


      
      console.log(this.fileRows);
    });
  }
  setFilePage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadFilesTable(this.filePage, null);
  }
  onFileSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadFilesTable(this.page, null);
  }

  downloadfile(row) {
    console.log(row.filePath);
    this.inspectionService.getFile(row.filePath).then((data) => {
      importedSaveAs(data, row.fileName);
    });
  }


}
