import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TestType } from '../../../../models/test-type';
import { InspectionService } from '../../../../services/inspection.service';
import { Inspection } from '../../../../models/inspection';
import { TestTypeQuestion } from '../../../../models/test-type-question';
import { Asset } from '../../../../models/asset';
import * as moment from 'moment';
import { AuthService } from '../../../../services/auth.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.css']
})
export class InspectionComponent implements OnInit {
  @Output() finished: EventEmitter<any> = new EventEmitter<any>();

  steps: any = [];
  currentStepIdx: number = 0;
  currentStep: null;

  testType: TestType;

  inspection: Inspection = new Inspection();
  assets: Asset[];

  constructor(private inspectionService: InspectionService, private authService: AuthService, private router: Router, private snotifyService: SnotifyService) {
    this.testType = inspectionService.testType;
    console.log("Test Type:" + this.testType.type);
    console.log(this.testType.type);
    if (this.testType == null || this.testType.isLolerOrPuwer) {
      this.steps.push({ idx: 0, name: "STEP 1" });
      this.steps.push({ idx: 1, name: "STEP 2" });
      this.steps.push({ idx: 2, name: "STEP 3" });
      this.steps.push({ idx: 3, name: "STEP 4" });

      this.currentStep = this.steps[0];
      //this.currentStep = this.steps[3];
      //this.currentStepIdx = 3;
    } else {
      this.steps.push({ idx: 0, name: "STEP 1" });

      if (this.testType.type != '') {
        this.steps.push({ idx: 1, name: "STEP 2" });
        this.steps.push({ idx: 2, name: "STEP 3" });
        this.steps.push({ idx: 3, name: "STEP 4" });
      }


      this.currentStep = this.steps[0];
    }
  }

  ngOnInit() {
  }

  step1Completed(dto) {
    this.inspection.firstExamination = dto.firstExam == "1";
    this.inspection.installedCorrectly = dto.installedCorrectly == "1";
    console.log(this.inspection);

    this.currentStepIdx++;
    this.currentStep = this.steps[this.currentStepIdx];
  }

  step2Completed(assets: Asset[]) {
    this.assets = assets;
    this.inspectionService.assetsToInspect = assets;

    this.currentStepIdx++;
    this.currentStep = this.steps[this.currentStepIdx];
  }

  step3Completed(dto) {
    this.inspection.immediateToPersons = dto.immediateToPersons == "1";
    this.inspection.becomeDangerToPersons = dto.becomeDangerToPersons == "1";
    this.inspection.repairDate = dto.repairDate;
    this.inspection.repair = dto.repair;
    this.inspection.particulars = dto.particulars;
    this.inspection.files = dto.files;
    this.currentStepIdx++;
    this.currentStep = this.steps[this.currentStepIdx];
  }


  async step4Completed(dto) {
    var inspectedAt = moment(dto.inspectionDate);
    inspectedAt = inspectedAt.add(moment().hour(), 'hours');
    console.log(dto)
    var fileLocationId = ''
    if (this.inspection.files.length > 0) {
      fileLocationId = UUID.UUID();
      var fileDTO = {
        files: this.inspection.files,
        fileLocationId: fileLocationId
      }
      this.inspectionService.uploadFiles(fileDTO);
    }
    this.inspection.fileLocationId = fileLocationId;

    if (this.inspectionService.projectId != null && this.inspectionService.projectId != "") 
      this.inspection.projectId = this.inspectionService.projectId;
    
    this.inspection.inspectedAt = moment(dto.inspectionDate).format("YYYY/MM/DD HH:mm");


    this.inspection.webUserId = this.authService.currentUser.value.id;
    this.inspection.testTypeId = this.inspectionService.testType.id;
    this.inspection.currentColourId = dto.currentColourId;


    var failedInspections = this.createFailedInspections(dto);

    for (let failedInspection of failedInspections) {
      await this.inspectionService.post(failedInspection);
    }

    var passedInspections = this.createPassedInspections(dto);


    for (let passedInspection of passedInspections) {
      console.log(passedInspection);
      await this.inspectionService.post(passedInspection);
    }

    this.snotifyService.success('Successfully saved inspection(s)');

    if (!this.inspectionService.isPopup) {
      this.router.navigate(['reports/inspections']);
    }

    var inspections = {
      passedInspections: passedInspections,
      failedInspection: failedInspections
    };

    this.finished.emit(inspections);
  }

  

  createFailedInspections(dto) {
    var inspections = [];
    var currentInspection = JSON.parse(JSON.stringify(this.inspection));

    for (let asset of dto.failedAssets) {

      var currentInspection = JSON.parse(JSON.stringify(this.inspection));

      currentInspection.assetId = asset.id;
      currentInspection.nextInspectionDate = moment(dto.failedInspectionDate).format("YYYY/MM/DD");
      if (this.inspectionService.projectId == null || this.inspectionService.projectId == "")
        currentInspection.newCurrentLocationId = dto.quarantineLocationId;
      currentInspection.safeForUse = false;

      currentInspection.answeredQuestions = [];
      for (let question of asset.questions) {
        var inspectionAnsweredQuestion = {
          testTypeQuestionId: question.id,
          testTypeQuestionResponseId: question.selectedResponse == null ? null : question.selectedResponse.id,
          comments: question.selectedComments
        };

        currentInspection.answeredQuestions.push(inspectionAnsweredQuestion);
      }

      inspections.push(currentInspection);
    }

    return inspections;
  }

  createPassedInspections(dto) {
    console.log(dto);
    var inspections = [];

    //var currentInspection = JSON.parse(JSON.stringify(this.inspection));

    for (let asset of dto.passedAssets) {

      var currentInspection = JSON.parse(JSON.stringify(this.inspection));

      currentInspection.assetId = asset.id;

      currentInspection.nextInspectionDate = moment(dto.passedInspectionDate).format("YYYY/MM/DD");
      if (this.inspectionService.projectId == null || this.inspectionService.projectId == "")
        currentInspection.newCurrentLocationId = dto.stockLocationId;

      currentInspection.repair = "";
      currentInspection.repairDate = "";
      currentInspection.safeForUse = true;

      currentInspection.answeredQuestions = [];
      for (let question of asset.questions) {
        var inspectionAnsweredQuestion = {
          testTypeQuestionId: question.id,
          testTypeQuestionResponseId: question.selectedResponse == null ? null : question.selectedResponse.id,
          //comments: question.comments
          comments: question.selectedComments
        };

        currentInspection.answeredQuestions.push(inspectionAnsweredQuestion);
      }


      inspections.push(currentInspection);
    }

    //console.log("INSPECTIONS");
    //console.log(inspections);
    return inspections;
  }
}
