import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Page } from '../../../../../models/paging/page';
import { SnotifyService } from 'ng-snotify';
import { ConfirmationService } from 'primeng/api';


@Component({
  selector: 'app-inspection-step3',
  templateUrl: './inspection-step3.component.html',
  styleUrls: ['./inspection-step3.component.css']
})
export class InspectionStep3Component implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter();


  rbImmediate = '0';
  rbDanger = '0';
  repairDate;
  repair;
  particulars;
  fileName;
  base64;
  file_count = 0;
  files = [];


  rows = Array<any>();
  page = new Page();

  constructor(public snotifyService: SnotifyService, private confirmationService: ConfirmationService) {
    this.page.pageNumber = 0;
    this.page.size = 10;
}

  ngOnInit() {
  }


  upload() {
    if (this.file_count < 4 && this.base64 != null && this.fileName != null) {
      if (!this.fileName.includes(".png") && !this.fileName.includes(".pdf") && !this.fileName.includes(".jpg")) {
        this.snotifyService.error("Invalid file type");
        return;
      }

      var dto = {
        base64: this.base64,
        fileName: this.fileName
      }
      this.files.push(dto);
      console.log(this.files);
      this.base64 = null;
      this.fileName = null;

      this.file_count += 1;
      this.loadTable(this.page);
      (<HTMLInputElement>document.getElementById('filePicker')).value = '';
      this.snotifyService.success("Successfully added file.");
    } else if (this.file_count == 4) {
      this.snotifyService.warning("You cannot add more than 4 files");
    } else if (this.fileName == null || this.fileName == '') {
      this.snotifyService.error("No file selected.");
    } else {
      this.snotifyService.error("Error adding files");
    }
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }
  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }
  loadTable(page: Page) {
    this.rows = this.files;
    this.page.totalElements = this.rows.length;
    this.page.pageNumber = 0;
    this.page.size = this.rows.length;
    
    console.log(this.rows);
  }

  remove(row) {
    let fileDetails = 'File Name: ' + row.fileName + '<br/>';
    this.confirmationService.confirm({
      message: 'Are you sure that you want to remove this file?</br><br/>' + fileDetails,
      accept: () => {
        let idx = this.files.indexOf(row);
        this.files.splice(idx, 1);
        this.file_count -= 1;
        this.loadTable(this.page);
        this.snotifyService.success("Successfully removed file.");
      }
    });
  }


  changeListener(e): void {
    var rawBase64 = null;
    var base64 = null;


    if (e.target.files.length > 0) {
      let file = e.target.files[0];


      if (file.size > 1024 * 1024) {
        (<HTMLInputElement>document.getElementById('filePicker')).value = '';
        this.snotifyService.error("Error: File size exceeds 1MB");
        return;
      }

      this.fileName = file.name;
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (e) => {
        rawBase64 = reader.result;
        base64 = rawBase64.split(",", 2);
        this.base64 = base64[1];
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }

  showNext() {
    if (this.rbImmediate == '0') {
      if (this.rbDanger == null) {
        return false;
      }

      if (this.rbDanger == '1' && (this.repair == null || this.repair == '')) {
        return false;
      }

      if (this.rbDanger == '1' && (this.repair == null || this.repair == '')) {
        return false;
      }
    }
    return true;
  }

  next() {
    var dto = {
      immediateToPersons: this.rbImmediate,
      becomeDangerToPersons: this.rbDanger,
      repairDate: this.repairDate,
      repair: this.repair,
      particulars: this.particulars,
      files: this.files
    }


    this.completed.emit(dto);
  }
}
