import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { Asset } from '../models/asset';
import { UUID } from 'angular2-uuid';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';
import { InCert } from '../models/inCert';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getHistory(assetId: string): Promise<Asset[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Asset[]>('/api/asset/gethistory?assetId=' + assetId).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getAll(): Promise<Asset[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Asset[]>('/api/asset/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public uploadFiles(files: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post('/api/asset/uploadFiles', files).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getFiles(page: Page, id: any): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'FileName'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&fileLocationId=' + id;

      this.http.get<any>('/api/asset/GetUploadedFilesForTable?size=' + page.size + '&pageNumber=' + page.pageNumber + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;

        console.log(pagedData);

        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }
  public getFile(filePath: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('/api/asset/getfile?path=' + filePath).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }


  public get(id: String): Promise<Asset> {
    return new Promise((resolve, reject) => {
      this.http.get<Asset>('/api/asset/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public checkInCert(id: String): Promise<InCert> {
    return new Promise((resolve, reject) => {
      this.http.get<InCert>('/api/asset/checkInCert?id=' + id).subscribe(data => {
        resolve(data);
        console.log(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public search(query: String, currentLocationId: String, categoryId: String, subCategoryId: String, itemTypeId: String, projectId: String): Promise<Asset[]> {
    return new Promise((resolve, reject) => {

      let params = '?query=' + query;
      params += '&currentLocationId=' + currentLocationId;
      params += '&categoryId=' + categoryId;
      params += '&subCategoryId=' + subCategoryId;
      params += '&itemTypeId=' + itemTypeId;
      params += '&projectId=' + projectId;

      
      this.http.get<Asset[]>('/api/asset/search' + params).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public searchNonExact(query: String, currentLocationId: String, categoryId: String, subCategoryId: String, itemTypeId: String, projectId: String): Promise<Asset[]> {
    return new Promise((resolve, reject) => {

      let params = '?query=' + query;
      params += '&currentLocationId=' + currentLocationId;
      params += '&categoryId=' + categoryId;
      params += '&subCategoryId=' + subCategoryId;
      params += '&itemTypeId=' + itemTypeId;
      params += '&projectId=' + projectId;


      this.http.get<Asset[]>('/api/asset/searchNonExact' + params).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }


  public getByItemType(itemTypeId: String): Promise<Asset[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Asset[]>('/api/asset/getbyitemtype?itemTypeId=' + itemTypeId).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(asset: Asset): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!asset.isEdit) {
        asset.id = UUID.UUID();
      }
      

      this.http.post('/api/asset/post', asset).subscribe(data => {
        resolve(data);
        if (!asset.isEdit) {
          this.snotifyService.success('Successfully added number asset');
        } else {
          this.snotifyService.success('Successfully updated numbered asset');
        }
        
      }, error => {
        console.log(error);
        reject();
      });

      //if (asset.isEdit) {
      //  this.http.put('/api/asset/', asset).subscribe(data => {
      //    resolve(data);
      //    this.snotifyService.success('Successfully updated numbered asset');
      //  }, error => {
      //    console.log(error);
      //    reject();
      //  });
      //} else {
      //  asset.id = UUID.UUID();

      //  this.http.post('/api/asset/post', asset).subscribe(data => {
      //    resolve(data);
      //    this.snotifyService.success('Successfully added number asset');
      //  }, error => {
      //    console.log(error);
      //    reject();
      //  });
      //}
    });
  }

  public getResults(page: Page, categoryId: string, subCategoryId: string, itemTypeId: string, canNumber: string, projectId: string = null, locationId: string = null, stockStatus: number = null): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'ItemTypeId'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&categoryId=' + categoryId;
      params += '&subCategoryId=' + subCategoryId;
      params += '&itemTypeId=' + itemTypeId;
      params += '&canNumber=' + canNumber;
      params += '&projectId=' + projectId;
      params += '&locationId=' + locationId;
      params += '&stockStatus=' + stockStatus;

      this.http.get<ServerData>('/api/asset/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }
}
