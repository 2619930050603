import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from '../../../../../models/category';
import { Owner } from 'src/app/models/owner';
import { OwnerService } from 'src/app/services/owner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { AssetService } from 'src/app/services/asset.service';
import { Asset } from 'src/app/models/asset';
import { Manufacturer } from 'src/app/models/manufacturer';
import { ManufacturerService } from 'src/app/services/manufacturer.service';
import * as moment from 'moment';
import { Page } from '../../../../../models/paging/page';
import { AssetStatusService } from 'src/app/services/asset-status.service';
import { AssetStatus } from 'src/app/models/asset-status';
import { CurrentColourService } from 'src/app/services/current-colour.service';
import { CurrentLocationService } from 'src/app/services/current-location.service';
import { CurrentColour } from 'src/app/models/current-colour';
import { CurrentLocation } from 'src/app/models/current-location';
import { Conformance } from 'src/app/models/conformance';
import { Supplier } from 'src/app/models/supplier';
import { ConformanceService } from 'src/app/services/conformance.service';
import { SupplierService } from 'src/app/services/supplier.service';
import { SubCategoryService } from '../../../../../services/sub-category.service';
import { ItemTypesService } from '../../../../../services/item-types.service';
import { SubCategory } from '../../../../../models/sub-category';
import { ItemType } from '../../../../../models/item-type';
import { AuthService } from 'src/app/services/auth.service';
import { SnotifyService } from 'ng-snotify';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-asset-normal-manage',
  templateUrl: './asset-normal-manage.component.html',
  styleUrls: ['./asset-normal-manage.component.css']
})
export class AssetNormalManageComponent implements OnInit {
  [x: string]: any;
  @Input() data: string;
  @Input() duplicate: boolean;
  @Output() saved: EventEmitter<any> = new EventEmitter();

  assetForm: FormGroup;

  categories: Category[];
  subCategories: SubCategory[];
  subCategoriesList: SubCategory[];
  itemTypes: ItemType[];
  itemTypesList: ItemType[];
  owners: Owner[];
  companies: Company[];
  manufacturers: Manufacturer[];
  statuses: AssetStatus[];
  currentColours: CurrentColour[];
  currentLocations: CurrentLocation[];
  conformances: Conformance[];
  suppliers: Supplier[];
  fileName;
  base64;
  file_count = 0;
  files = [];


  rows = Array<any>();
  page = new Page();


  isEdit: boolean = false;

  hired: boolean = false;

  asset: Asset;

  prevCan: String;

  userRoleId: number;

  constructor(private categoryService: CategoryService, private subCategoryService: SubCategoryService, private itemTypesService: ItemTypesService, private ownerService: OwnerService,
    private companyService: CompanyService, private assetService: AssetService, private manufacturerService: ManufacturerService,
    private currentColourService: CurrentColourService, private currentLocationService: CurrentLocationService,
    private conformanceService: ConformanceService, private supplierService: SupplierService,
    private assetStatusService: AssetStatusService, private modalService: NgbModal, private confirmationService: ConfirmationService, private fb: FormBuilder, authService: AuthService,
    private snotifyService: SnotifyService) {
    this.asset = new Asset();
    this.userRoleId = authService.currentUser.value.userType;
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.categoryService.getAll().then((data) => {
      this.categories = data;
      this.subCategoryService.getAll().then((data) => {
        this.subCategoriesList = data;
        this.itemTypesService.getNumbered().then((data) => {
          
          this.itemTypesList = data;

          if (this.data != undefined) {
            this.assetService.get(this.data).then((data) => {
              if (this.duplicate) {
                this.prevCan = data.canNumber;
                data.id = null;
                data.serialNum = "";
                data.canNumber = "";
                data.rfid = "";
              }
              this.asset = data;
              console.log("Asset");
              console.log(this.asset);

              if (this.asset.files != undefined && this.asset.files != null && this.asset.files.length > 0) {
                this.files = this.asset.files;
                this.file_count = this.asset.files.length;
                this.loadTable(this.page);
              }

              if (!this.duplicate) {
                this.isEdit = true;

              }
              else {
                console.log("Duplicating: " + this.duplicate);
              }

              this.itemTypes = this.itemTypesList.filter(d => {
                return d.id == this.asset.itemTypeId;
              });

              var itemType = this.itemTypesList.find(d => {
                return d.id == this.asset.itemTypeId;
              });

              this.subCategories = this.subCategoriesList.filter(c => {
                return c.id == itemType.subCategoryId;
              });

              var cat = this.subCategoriesList.find(c => {
                return c.id == itemType.subCategoryId;
              });
              console.log(this.asset.fileLocationId);
              this.assetForm.patchValue({ fileLocationId: this.asset.fileLocationId });

              this.assetForm.patchValue({ categoryId: cat.categoryId });
              this.assetForm.patchValue({ subCategoryId: cat.id });
              
              if (this.asset.ownerId == '13ff8edf-c4eb-4164-92c2-c5445ce3ae7a') {
                this.hired = true;
                this.addCompanyFormControl(true);
              }

              data.purchaseDate = moment(data.purchaseDate).toDate();

              this.assetForm.patchValue(data);

              this.onCategoryChange(this.asset.itemType.subCategory.categoryId);
              this.onSubCategoryChange(this.asset.itemType.subCategoryId);

            });
          }
        })
      });
    });

    this.ownerService.getAll().then((data) => {
      this.owners = data;
    });

    this.companyService.getAll().then((data) => {
      this.companies = data;
    });

    this.manufacturerService.getAll().then((data) => {
      this.manufacturers = data;
    });

    this.assetStatusService.getAll().then((data) => {
      this.statuses = data;
    });

    this.currentColourService.getAll().then((data) => {
      this.currentColours = data;
    });

    this.currentLocationService.getAll().then((data) => {
      this.currentLocations = data;
    });

    this.conformanceService.getAll().then((data) => {
      this.conformances = data;
    });

    this.supplierService.getAll().then((data) => {
      this.suppliers = data;
    });

    this.initForm();
  }

  initForm() {
    this.assetForm = this.fb.group({
      categoryId: ['', Validators.required],
      subCategoryId: ['', Validators.required],
      itemTypeId: ['', Validators.required],
      ownerId: ['', Validators.required],
      manufacturerId: ['', Validators.required],
      model: ['', Validators.required],
      serialNum: ['', Validators.required],
      canNumber: [''],
      supplierId: ['', Validators.required],
      purchaseDate: [moment().toDate(), Validators.required],
      conformanceId: ['', Validators.required],
      swl: [''],
      currentColourId: ['', Validators.required],
      currentLocationId: ['', Validators.required],
      assetStatusId: ['b2f0569a-b8cb-4c17-8123-c1c22255e0bb', Validators.required], // In-Use
      rfid: [''],
      poNumber: [''],
      files: [null],
      fileLocationId: ['']
    });
  }

  onCategoryChange(value) {
    this.subCategories = this.subCategoriesList.filter(c => {
      return value == null || c.categoryId == value;
    });
  }

  onSubCategoryChange(value) {
    this.itemTypes = this.itemTypesList.filter(c => {
      return value == null || c.subCategoryId == value;
    });
  }

  onOwnerChange(value) {
    if (value == '13ff8edf-c4eb-4164-92c2-c5445ce3ae7a') {
      this.hired = true;

      this.addCompanyFormControl(this.hired);
    } else {
      this.hired = false;

      this.addCompanyFormControl(this.hired);
    }
  }

  addCompanyFormControl(hired) {
    console.log("INSIDE");

    if (hired) {
      this.assetForm.addControl('companyId', new FormControl('', Validators.required));
    } else {
      this.assetForm.removeControl('companyId');
    }
  }

  companyModalRef;
  showCompanyModal(content) {
    console.log(content);
    this.companyModalRef = this.modalService.open(content, { centered: true, size: 'lg' });
    this.companyModalRef.result.then((result) => { }, (reason) => { });
  }

  companyAdded(company) {
    // Add this company and then put it in our list
    this.companyModalRef.dismiss();

    this.companyService.save(company).then((newCompany) => {
      this.companyService.getAll().then((data) => {
        this.companies = data;

        this.assetForm.patchValue({ companyId: newCompany.id });
      });
    })
  }

  manufacturerModalRef;
  showManufacturerModal(content) {
    this.manufacturerModalRef = this.modalService.open(content, { centered: true, size: 'lg' });
    this.manufacturerModalRef.result.then((result) => { }, (reason) => { });
  }

  manufacturerAdded(manufacturer) {
    // Add this manufacturer and then put it in our list
    this.manufacturerModalRef.dismiss();

    this.manufacturerService.save(manufacturer).then((newManuf) => {
      this.manufacturerService.getAll().then((data) => {
        this.manufacturers = data;

        this.assetForm.patchValue({ manufacturerId: newManuf.id });
      });
    })
  }

  supplierModalRef;
  showSupplierModal(content) {
    this.supplierModalRef = this.modalService.open(content, { centered: true, size: 'lg' });
    this.supplierModalRef.result.then((result) => { }, (reason) => { });
  }

  supplierAdded(supplier) {
    // Add this supplier and then put it in our list
    this.supplierModalRef.dismiss();

    this.supplierService.save(supplier).then((newSupp) => {
      this.supplierService.getAll().then((data) => {
        this.suppliers = data;

        this.assetForm.patchValue({ supplierId: newSupp.id });
      });
    })
  }

  submit() {
    if (this.isEdit && (this.userRoleId == 1 || this.userRoleId == 3)) {
      if (this.assetForm.value.canNumber == null || this.assetForm.value.canNumber.trim() == "") {
        this.snotifyService.error("CAN Number is required");
        return;
      }
    }
    this.assetForm.value.files = this.files;
    if (this.assetForm.valid) {
      this.saved.emit(this.assetForm.value);
    } else {
      this.findInvalidControls();
    }
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.assetForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid);

    return invalid;
  }


  CurrentlocationModalRef;
  showCurrentLocationModal(content) {
    this.CurrentlocationModalRef = this.modalService.open(content, { centered: true, size: 'lg' });
    this.CurrentlocationModalRef.result.then((result) => { }, (reason) => { });
  }
  CurrentlocationAdded(currentLocations) {
    // Add this Location and then put it in our list
    this.CurrentlocationModalRef.dismiss();

    this.currentLocationService.save(currentLocations).then((newLocation) => {
      this.currentLocationService.getAll().then((data) => {
        this.currentLocations = data;
        this.assetForm.patchValue({ currentLocationId: newLocation.id });
      });
    })
  }

  uploadfile() {

    var dto = {
      base64: this.base64,
      fileName: this.fileName
    }
    this.files.push(dto);
    console.log(this.files);
    this.base64 = null;
    this.fileName = null;

    this.file_count += 1;
    this.loadTable(this.page);
    (<HTMLInputElement>document.getElementById('filePicker')).value = '';
    this.snotifyService.success("Successfully added file.");
  }

  upload() {
    if (this.file_count < 4 && this.base64 != null && this.fileName != null) {
      if (!this.fileName.includes(".png") && !this.fileName.includes(".pdf") && !this.fileName.includes(".jpg")) {
        this.snotifyService.error("Invalid file type");
        return;
      }

      if (this.isEdit || this.duplicate) {
        let fileDetails = 'File Name: ' + this.fileName + '<br/>';
        this.confirmationService.confirm({
          message: 'Are you sure you want to add a file to this asset and any duplicated assets?</br><br/>' + fileDetails,
          accept: () => {
            this.uploadfile();
          },
          reject: () => {
            (<HTMLInputElement>document.getElementById('filePicker')).value = '';
          }
        });
      } else {
        this.uploadfile();
      }

    } else if (this.file_count == 4) {
      this.snotifyService.warning("You cannot add more than 4 files");
    } else if (this.fileName == null || this.fileName == '') {
      this.snotifyService.error("No file selected.");
    } else {
      this.snotifyService.error("Error adding files");
    }
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }
  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }
  loadTable(page: Page) {
    this.rows = this.files;
    this.page.totalElements = this.rows.length;
    this.page.pageNumber = 0;
    this.page.size = this.rows.length;

    console.log(this.rows);
  }

  remove(row) {
    let fileDetails = 'File Name: ' + row.fileName + '<br/>';
    let editWarning = '';
    if (this.isEdit || this.duplicate) {
      editWarning = '<br/>WARNING: REMOVING THIS FILE WILL REMOVE IT FOR ALL DUPLICATED ASSETS</br><br/>'
    };

    this.confirmationService.confirm({
      message: 'Are you sure that you want to remove this file?</br><br/>' + editWarning + fileDetails,
      accept: () => {
        let idx = this.files.indexOf(row);
        this.files.splice(idx, 1);
        this.file_count -= 1;
        this.loadTable(this.page);
        this.snotifyService.success("Successfully removed file.");
      }
    });
  }


  changeListener(e): void {
    var rawBase64 = null;
    var base64 = null;


    if (e.target.files.length > 0) {
      let file = e.target.files[0];


      if (file.size > 1024 * 1024) {
        (<HTMLInputElement>document.getElementById('filePicker')).value = '';
        this.snotifyService.error("Error: File size exceeds 1MB");
        return;
      }

      this.fileName = file.name;
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (e) => {
        rawBase64 = reader.result;
        base64 = rawBase64.split(",", 2);
        this.base64 = base64[1];
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }

  get categoryId() {
    return this.assetForm.get('categoryId');
  }

  get subCategoryId() {
    return this.assetForm.get('subCategoryId');
  }

  get itemTypeId() {
    return this.assetForm.get('itemTypeId');
  }

  get manufacturerId() {
    return this.assetForm.get('manufacturerId');
  }

  get model() {
    return this.assetForm.get('model');
  }

  get serialNum() {
    return this.assetForm.get('serialNum');
  }

  get canNum() {
    return this.assetForm.get('canNum');
  }

  get ownerId() {
    return this.assetForm.get('ownerId');
  }

  get supplierId() {
    return this.assetForm.get('supplierId');
  }

  get conformanceId() {
    return this.assetForm.get('conformanceId');
  }

  get currentColourId() {
    return this.assetForm.get('currentColourId');
  }

  get currentLocationId() {
    return this.assetForm.get('currentLocationId');
  }
}
