import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule, Pipe } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule as PrimePicker } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import 'hammerjs';
import 'mousetrap';
import { NgxPrintModule } from 'ngx-print';
import { ModalService } from './services/modal.service'
import { PopUpModalComponent } from '../app/components/popup-modal/popup-modal.component';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { UserComponent } from './layout/user/user.component';
import { LoginComponent } from './pages/login/login.component';

import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './services/auth.service';
import { FilterService } from './services/filter.service';
import { RoleGuard } from './services/role.guard';
import { UserGuard } from './services/user.guard';

import { WebUsersComponent } from './pages/administration/web-users/web-users.component';
import { WebUserService } from './services/web-user.service';
import { WebManageComponent } from './pages/administration/web-users/web-manage/web-manage.component';
import { TabletUsersComponent } from './pages/administration/tablet-users/tablet-users.component';
import { TabletUserService } from './services/tablet-user.service';
import { TabletUserManageComponent } from './pages/administration/tablet-users/tablet-user-manage/tablet-user-manage.component';
import { OwnerService } from './services/owner.service';
import { CompanyService } from './services/company.service';
import { CompaniesComponent } from './pages/administration/companies/companies.component';
import { CompanyManageComponent } from './pages/administration/companies/company-manage/company-manage.component';
import { CompanyManageDetailComponent } from './pages/administration/companies/company-manage/company-manage-detail/company-manage-detail.component';
import { DivisionService } from './services/division.service';
import { CarrierService } from './services/carrier.service';
import { CarriersComponent } from './pages/administration/carriers/carriers.component';
import { CarrierManageComponent } from './pages/administration/carriers/carrier-manage/carrier-manage.component';
import { CarrierManageDetailComponent } from './pages/administration/carriers/carrier-manage/carrier-manage-detail/carrier-manage-detail.component';
import { ReportableService } from './services/reportable.service';
import { DivisionsComponent } from './pages/administration/divisions/divisions.component';
import { DivisionManageComponent } from './pages/administration/divisions/division-manage/division-manage.component';
import { DivisionManageDetailComponent } from './pages/administration/divisions/division-manage/division-manage-detail/division-manage-detail.component';
import { ManufacturersComponent } from './pages/administration/manufacturers/manufacturers.component';
import { ManufacturerManageComponent } from './pages/administration/manufacturers/manufacturer-manage/manufacturer-manage.component';
import { ManufacturerManageDetailComponent } from './pages/administration/manufacturers/manufacturer-manage/manufacturer-manage-detail/manufacturer-manage-detail.component';
import { ManufacturerService } from './services/manufacturer.service';
import { CategoriesComponent } from './pages/administration/categories/categories.component';
import { CategoryManageComponent } from './pages/administration/categories/category-manage/category-manage.component';
import { SubCategoriesComponent } from './pages/administration/sub-categories/sub-categories.component';
import { SubCategoryManageComponent } from './pages/administration/sub-categories/sub-category-manage/sub-category-manage.component';
import { SubCategoryService } from './services/sub-category.service';
import { CategoryService } from './services/category.service';
import { ItemTypesService } from './services/item-types.service';
import { ItemTypesComponent } from './pages/administration/itemtypes/itemtypes.component';
import { TestTypeService } from './services/test-type.service';
import { ItemTypesManageComponent } from './pages/administration/itemtypes/itemtypes-manage/itemtypes-manage.component';
import { AssetService } from './services/asset.service';
import { AssetStatusService } from './services/asset-status.service';
import { AssetInspectionType } from './models/asset-inspection-type';
import { AssetInspectionTypeService } from './services/asset-inspection-type.service';
import { NonNumberedAssetService } from './services/non-numbered-asset.service';
import { AssetsComponent } from './pages/administration/assets/assets.component';
import { AssetsManageComponent } from './pages/administration/assets/assets-manage/assets-manage.component';
import { AssetNonNumberedManageComponent } from './pages/administration/assets/assets-manage/asset-non-numbered-manage/asset-non-numbered-manage.component';
import { AssetNormalManageComponent } from './pages/administration/assets/assets-manage/asset-normal-manage/asset-normal-manage.component';
import { ProjectService } from './services/project.service';
import { ProjectsComponent } from './pages/administration/projects/projects.component';
import { ProjectManageComponent } from './pages/administration/projects/project-manage/project-manage.component';
import { PicklistService } from './services/picklist.service';
import { PicklistsComponent } from './pages/administration/picklists/picklists.component';
import { PicklistManageComponent } from './pages/administration/picklists/picklist-manage/picklist-manage.component';
import { Step1Component } from './pages/administration/picklists/picklist-manage/step1/step1.component';
import { Step2Component } from './pages/administration/picklists/picklist-manage/step2/step2.component';
import { PicklistNewComponent } from './pages/administration/picklists/picklist-manage/step2/picklist-new/picklist-new.component';
import { PicklistCategoriesComponent } from './pages/administration/picklists/picklist-manage/step2/components/picklist-categories/picklist-categories.component';
import { PicklistItemTypesComponent } from './pages/administration/picklists/picklist-manage/step2/components/picklist-item-types/picklist-item-types.component';
import { PicklistExistingComponent } from './pages/administration/picklists/picklist-manage/step2/picklist-existing/picklist-existing.component';
import { LinkedItemsComponent } from './pages/administration/itemtypes/linked-items/linked-items.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PicklistTemplateComponent } from './pages/administration/picklists/picklist-manage/step2/picklist-template/picklist-template.component';
import { ActivePicklistComponent } from './pages/stores/active-picklist/active-picklist.component';
import { ActivePicklistReportComponent } from './pages/stores/active-picklist-report/active-picklist-report.component';
import { SelectPicklistComponent } from './pages/stores/active-picklist/select-picklist/select-picklist.component';
import { SelectPicklistReportComponent } from './pages/stores/active-picklist-report/select-picklist-report/select-picklist-report.component';
import { CallbackPipe } from './pipes/callback-pipe';
//import { PicklistNewComponent } from './pages/administration/picklists/picklist-manage/step2/picklist-new/picklist-new.component';
//import { PicklistExistingComponent } from './pages/administration/picklists/picklist-manage/step2/picklist-existing/picklist-existing.component';
//import { PicklistTemplateComponent } from './pages/administration/picklists/picklist-manage/step2/picklist-template/picklist-template.component';
import { NgPipesModule } from 'ngx-pipes';
import { InspectionsComponent } from './pages/reports/inspections/inspections.component';
import { ApiHttpInterceptor } from './services/api-http-interceptor';
import { InspectionService } from './services/inspection.service';
import { AssetDetailsComponent } from './pages/administration/assets/asset-details/asset-details.component';
import { TestTypesComponent } from './pages/administration/test-types/test-types.component';
import { TestTypeManageComponent } from './pages/administration/test-types/test-type-manage/test-type-manage.component';
import { TestTypeQuestionsComponent } from './pages/administration/test-types/test-type-questions/test-type-questions.component';
import { TestTypeQuestionManageComponent } from './pages/administration/test-types/test-type-questions/test-type-question-manage/test-type-question-manage.component';
import { TestTypeQuestionDetailComponent } from './pages/administration/test-types/test-type-questions/test-type-question-detail/test-type-question-detail.component';
import { LocationsComponent } from './pages/administration/locations/locations.component';
import { LocationManageComponent } from './pages/administration/locations/location-manage/location-manage.component';
import { AssetSelectorComponent } from './components/asset-selector/asset-selector.component';
import { AssetSelectorService } from './services/asset-selector.service';
import { RoteComponent } from './pages/stores/rote/rote.component';
import { SelectStockComponent } from './pages/stores/rote/select-stock/select-stock.component';
import { SelectProjectComponent } from './pages/stores/rote/select-project/select-project.component';
import { InspectionComponent } from './pages/stores/rote/inspection/inspection.component';
import { InspectionStep1Component } from './pages/stores/rote/inspection/inspection-step1/inspection-step1.component';
import { InspectionStep2Component } from './pages/stores/rote/inspection/inspection-step2/inspection-step2.component';
import { InspectionStep3Component } from './pages/stores/rote/inspection/inspection-step3/inspection-step3.component';
import { InspectionStep4Component } from './pages/stores/rote/inspection/inspection-step4/inspection-step4.component';
import { SortByPipe } from './pipes/orderby-pipe';
import { BookInComponent } from './pages/stores/book-in/book-in.component';
import { SuppliersComponent } from './pages/administration/suppliers/suppliers.component';
import { SupplierManageComponent } from './pages/administration/suppliers/supplier-manage/supplier-manage.component';
import { SupplierManageDetailComponent } from './pages/administration/suppliers/supplier-manage/supplier-manage-detail/supplier-manage-detail.component';
import { TabletUserRegimesComponent } from './pages/administration/tablet-users/tablet-user-regimes/tablet-user-regimes.component';
import { WebUserRegimesComponent } from './pages/administration/web-users/web-user-regimes/web-user-regimes.component';
import { NonNumberedAssetsComponent } from './pages/administration/non-numbered-assets/non-numbered-assets.component';
import { NonNumberedAsset } from './models/non-numbered-asset';
import { NonNumberedAssetManageComponent } from './pages/administration/non-numbered-assets/non-numbered-asset-manage/non-numbered-asset-manage.component';
import { ItemTypeSelectorComponent } from './components/item-type-selector/item-type-selector.component';
import { RegimeGroupsComponent } from './pages/administration/regime-groups/regime-groups.component';
import { RegimeGroupManageComponent } from './pages/administration/regime-groups/regime-group-manage/regime-group-manage.component';
import { RegimeGroupService } from './services/regime-group.service';
import { CurrentLocationManageDetailComponent } from './pages/administration/locations/location-manage/location-manage-detail/location-manage-detail.component';
import { BookInNonNumberedComponent } from './pages/stores/book-in-non-numbered/book-in-non-numbered.component';
import { NonNumberedAssetsStoresComponent } from './pages/stores/non-numbered-assets/non-numbered-assets.component';
import { InspectionEditComponent } from './pages/reports/inspections/inspections-edit/inspections-edit.component';
import { JobExpiryComponent } from './pages/reports/job-expiry/job-expiry.component';
import { NumberedItemsReportComponent } from './pages/reports/numbered-items/numbered-items.component';
import { NonNumberedItemsReportComponent } from './pages/reports/non-numbered-items/non-numbered-items.component';
import { OutgoingPicklistComponent } from './pages/reports/outgoing-picklist/outgoing-picklist.component';
import { GroupByPipe } from './pipes/groupby-pipe';


@Pipe({ name: 'safeHtml' })
export class Safe {
  constructor(private sanitizer: DomSanitizer) { }

  transform(style) {
    //return this.sanitizer.bypassSecurityTrustStyle(style);
    return this.sanitizer.bypassSecurityTrustHtml(style);
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LayoutComponent,
    UserComponent,
    LoginComponent,
    WebUsersComponent,
    WebManageComponent,
    TabletUsersComponent,
    TabletUserManageComponent,
    CompaniesComponent,
    CompanyManageComponent,
    CompanyManageDetailComponent,
    CarriersComponent,
    CarrierManageComponent,
    CarrierManageDetailComponent,
    Safe,
    DivisionsComponent,
    DivisionManageComponent,
    DivisionManageDetailComponent,
    ManufacturersComponent,
    ManufacturerManageComponent,
    ManufacturerManageDetailComponent,
    CurrentLocationManageDetailComponent,
    CategoriesComponent,
    CategoryManageComponent,

    SubCategoriesComponent,
    SubCategoryManageComponent,

    ItemTypesComponent,
    ItemTypesManageComponent,
    LinkedItemsComponent,

    AssetsComponent,
    AssetsManageComponent,
    AssetNonNumberedManageComponent,
    AssetNormalManageComponent,

    ProjectsComponent,
    ProjectManageComponent,

    PicklistsComponent,
    PicklistManageComponent,
    Step1Component,
    Step2Component,
    PicklistNewComponent,
    PicklistCategoriesComponent,
    PicklistItemTypesComponent,
    PicklistExistingComponent,
    PicklistTemplateComponent,
    ActivePicklistComponent,
    ActivePicklistReportComponent,
    SelectPicklistComponent,
    SelectPicklistReportComponent,
    //PicklistNewComponent,
    //PicklistExistingComponent,
    //PicklistTemplateComponent
    TestTypesComponent,
    TestTypeQuestionsComponent,
    TestTypeManageComponent,
    TestTypeQuestionDetailComponent,
    TestTypeQuestionManageComponent,
    PopUpModalComponent,

    CallbackPipe,
    SortByPipe,
    GroupByPipe,
    InspectionsComponent,
    NumberedItemsReportComponent,
    NonNumberedItemsReportComponent,
    OutgoingPicklistComponent,
    AssetDetailsComponent,
    LocationsComponent,
    LocationManageComponent,
    AssetSelectorComponent,
    RoteComponent,
    SelectStockComponent,
    SelectProjectComponent,
    InspectionComponent,
    InspectionStep1Component,
    InspectionStep2Component,
    InspectionStep3Component,
    InspectionStep4Component,
    BookInComponent,
    SuppliersComponent,
    SupplierManageComponent,
    SupplierManageDetailComponent,
    TabletUserRegimesComponent,
    WebUserRegimesComponent,
    NonNumberedAssetsComponent,
    NonNumberedAssetManageComponent,
    ItemTypeSelectorComponent,
    RegimeGroupsComponent,
    RegimeGroupManageComponent,
    BookInNonNumberedComponent,
    NonNumberedAssetsStoresComponent ,
    InspectionEditComponent,
    JobExpiryComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SnotifyModule,
    NgHttpLoaderModule,
    NgxDatatableModule,
    UiSwitchModule,
    NgbModule.forRoot(),
    PrimePicker,
    ConfirmDialogModule,
    NgxPrintModule,
    NgSelectModule,
    NgPipesModule,
    RouterModule.forRoot([
      { path: '', redirectTo: '/dashboard', pathMatch: 'full', canActivate: [RoleGuard] },
      { path: 'login', component: LoginComponent },
      { path: 'dashboard', component: HomeComponent, canActivate: [RoleGuard] },
      {
        path: 'inspections', canActivate: [RoleGuard], children: [
          {
            path: 'rote', children: [
              { path: '', component: RoteComponent },
              { path: 'inspect', component: InspectionComponent },
            ]
          },
        ]
      },
      {
        path: 'stores', canActivate: [RoleGuard], children: [
          {
            path: 'active-picklists', children: [
              { path: '', component: ActivePicklistComponent },
              { path: 'select/:id', component: SelectPicklistComponent }
            ]
          },
          {
            path: 'active-picklists-report', children: [
              { path: '', component: ActivePicklistReportComponent },
              { path: 'select/:id', component: SelectPicklistReportComponent }
            ]
          },
          {
            path: 'bookin', children: [
              { path: '', component: BookInComponent },
            ]
          },
          {
            path: 'bookinnonnumbered', children: [
              { path: '', component: BookInNonNumberedComponent },
            ]
          },
          //{
          //  path: 'nonnumbered', children: [
          //    { path: '', component: NonNumberedAssetsStoresComponent },
          //    { path: 'manage', component: NonNumberedAssetManageComponent },
          //    { path: 'manage/:id', component: NonNumberedAssetManageComponent },
          //  ]
          //},
          {
            path: 'assets', children: [
              { path: '', component: AssetsComponent },
              { path: 'manage', component: AssetsManageComponent },
              { path: 'manage/:id', component: AssetsManageComponent },
              { path: 'details/:id', component: AssetDetailsComponent }
            ]
          },
          {
            path: 'nonnumberedassets', children: [
              { path: '', component: NonNumberedAssetsComponent },
              { path: 'manage', component: NonNumberedAssetManageComponent },
              { path: 'manage/:id', component: NonNumberedAssetManageComponent },
            ]
          },
        ]
      },
      {
        path: 'reports', children: [
          {
            path: 'inspections', children: [
              { path: '', component: InspectionsComponent },
              { path: 'edit', component: InspectionEditComponent },
              { path: 'edit/:id', component: InspectionEditComponent },
            ]
          },
          {
            path: 'jobexpiry', children: [
              { path: '', component: JobExpiryComponent },

            ]

          },

          {
            path: 'inspections/:id', children: [
              { path: '', component: InspectionsComponent },
              { path: 'edit', component: InspectionEditComponent },
              { path: 'edit/:id', component: InspectionEditComponent },

            ]
          },
          {
            path: 'numbered-items', children: [
              { path: '', component: NumberedItemsReportComponent },
              //{ path: 'edit', component: InspectionEditComponent },
              //{ path: 'edit/:id', component: InspectionEditComponent },
            ]
          },
          {
            path: 'non-numbered-items', children: [
              { path: '', component: NonNumberedItemsReportComponent },
            ]
          },
          {
            path: 'outgoing-picklist', children: [
              { path: '', component: OutgoingPicklistComponent },
            ]
          },
        ]
      },
      {
        path: 'users', canActivate: [RoleGuard, UserGuard], children: [
          {
            path: 'tablet', children: [
              { path: '', component: TabletUsersComponent },
              { path: 'manage', component: TabletUserManageComponent },
              { path: 'manage/:id', component: TabletUserManageComponent },
              { path: 'regimes/:id', component: TabletUserRegimesComponent },
            ]
          },
          {
            path: 'web', children: [
              { path: '', component: WebUsersComponent },
              { path: 'manage', component: WebManageComponent },
              { path: 'manage/:id', component: WebManageComponent },
              { path: 'regimes/:id', component: WebUserRegimesComponent },
            ]
          }
        ]
      },
      {
        path: 'administration', canActivate: [RoleGuard], children: [
          {
            path: 'locations', children: [
              { path: '', component: LocationsComponent },
              { path: 'manage', component: LocationManageComponent },
              { path: 'manage/:id', component: LocationManageComponent }
            ]
          },
          {
            path: 'categories', children: [
              { path: '', component: CategoriesComponent },
              { path: 'manage', component: CategoryManageComponent },
              { path: 'manage/:id', component: CategoryManageComponent }
            ]
          },
          {
            path: 'subcategories', children: [
              { path: '', component: SubCategoriesComponent },
              { path: 'manage', component: SubCategoryManageComponent },
              { path: 'manage/:id', component: SubCategoryManageComponent }
            ]
          },
          {
            path: 'itemtypes', children: [
              { path: '', component: ItemTypesComponent },
              { path: 'manage', component: ItemTypesManageComponent },
              { path: 'manage/:id', component: ItemTypesManageComponent },
              { path: 'linkeditems/:id', component: LinkedItemsComponent }
            ]
          },
          {
            path: 'divisions', children: [
              { path: '', component: DivisionsComponent },
              { path: 'manage', component: DivisionManageComponent },
              { path: 'manage/:id', component: DivisionManageComponent }
            ]
          },
          {
            path: 'manufacturers', children: [
              { path: '', component: ManufacturersComponent },
              { path: 'manage', component: ManufacturerManageComponent },
              { path: 'manage/:id', component: ManufacturerManageComponent }
            ]
          },
          {
            path: 'suppliers', children: [
              { path: '', component: SuppliersComponent },
              { path: 'manage', component: SupplierManageComponent },
              { path: 'manage/:id', component: SupplierManageComponent }
            ]
          },
          {
            path: 'assets', children: [
              { path: '', component: AssetsComponent },
              { path: 'manage', component: AssetsManageComponent },
              { path: 'manage/:id', component: AssetsManageComponent },
              { path: 'details/:id', component: AssetDetailsComponent }
            ]
          },
          {
            path: 'nonnumberedassets', children: [
              { path: '', component: NonNumberedAssetsComponent },
              { path: 'manage', component: NonNumberedAssetManageComponent },
              { path: 'manage/:id', component: NonNumberedAssetManageComponent },
            ]
          },
          {
            path: 'projects', children: [
              { path: '', component: ProjectsComponent },
              { path: 'manage', component: ProjectManageComponent },
              { path: 'manage/:id', component: ProjectManageComponent }
            ]
          },
          {
            path: 'picklists', children: [
              { path: '', component: PicklistsComponent },
              { path: 'manage', component: PicklistManageComponent },
              { path: 'manage/:id', component: PicklistManageComponent }
            ]
          },
          {
            path: 'testgroups', children: [
              { path: '', component: RegimeGroupsComponent },
              { path: 'manage', component: RegimeGroupManageComponent },
              { path: 'manage/:id', component: RegimeGroupManageComponent }
            ]
          },
          {
            path: 'testtypes', children: [
              { path: '', component: TestTypesComponent },
              { path: 'manage', component: TestTypeManageComponent },
              { path: 'manage/:id', component: TestTypeManageComponent },
              {
                path: 'questions', children: [
                  { path: '', component: TestTypeQuestionsComponent },
                  { path: 'manage', component: TestTypeQuestionManageComponent },
                  { path: 'manage/:id', component: TestTypeQuestionManageComponent }
                ]
              },
              {
                path: 'questions/:testtypeid', children: [
                  { path: '', component: TestTypeQuestionsComponent },
                  { path: 'manage', component: TestTypeQuestionManageComponent },
                  { path: 'manage/:id', component: TestTypeQuestionManageComponent }
                ]
              },
            ]
          },
        ]
      },
    ])
  ],
  providers: [
    CookieService,
    AuthService,
    RoleGuard,
    UserGuard,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    ConfirmationService,
    WebUserService,
    TabletUserService,
    OwnerService,
    CompanyService,
    DivisionService,
    CarrierService,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    ReportableService,
    ManufacturerService,
    CategoryService,
    SubCategoryService,
    ItemTypesService,
    TestTypeService,
    AssetService,
    AssetStatusService,
    AssetInspectionTypeService,
    NonNumberedAssetService,
    ProjectService,
    PicklistService,
    InspectionService,
    AssetSelectorService,
    RegimeGroupService,
    FilterService,
    ModalService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export class NgbdDatepickerAdapter {

  model1: Date;
  model2: Date;

  get today() {
    return new Date();
  }
}
