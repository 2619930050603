import { Component, OnInit, ViewChild } from '@angular/core';
import { AssetNormalManageComponent } from './asset-normal-manage/asset-normal-manage.component';
import { AssetNonNumberedManageComponent } from './asset-non-numbered-manage/asset-non-numbered-manage.component';
import { AssetService } from 'src/app/services/asset.service';
import { NonNumberedAssetService } from 'src/app/services/non-numbered-asset.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-assets-manage',
  templateUrl: './assets-manage.component.html',
  styleUrls: ['./assets-manage.component.css']
})
export class AssetsManageComponent implements OnInit {
  @ViewChild(AssetNormalManageComponent) normalAsset: AssetNormalManageComponent;
  @ViewChild(AssetNonNumberedManageComponent) nonNumberedAsset: AssetNonNumberedManageComponent;

  numbered: boolean = true;
  id: any;
  numberedAsset: boolean;
  isEdit: boolean = false;
  duplicate: boolean = false;

  constructor(private assetService: AssetService, private nonNumberedAssetService: NonNumberedAssetService, public router: Router, public route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      if (params["duplicate"])
        this.duplicate = params["duplicate"];

      if (this.id != undefined && this.id != null) {
        console.log("THIS EDIT 1");
        this.isEdit = true;
        if (this.duplicate != null && this.duplicate)
          this.isEdit = false;
      }

      var numberedAsset = params['numberedAsset'];
      if (numberedAsset != undefined) {
        this.numberedAsset = JSON.parse(numberedAsset);
        if (this.numberedAsset != undefined) {
          this.numbered = this.numberedAsset;
        }
      }
      console.log("duplicate: " + this.duplicate);
      console.log("edit: " + this.isEdit);
    });

  }

  onSaved(asset: any) {
    console.log(asset);
    if (this.numbered) {
      this.saveAsset(asset);
    } else {
      this.saveNonNumberedAsset(asset);
    }
  }

  saveAsset(asset) {
    console.log("Asset being saved");
    console.log(asset);

    if (this.id != undefined && this.id != null) {
      asset.id = this.id;
      asset.isEdit = true;
      if (this.duplicate != null && this.duplicate) {
        console.log("EDIT 6")
        asset.id = undefined;
        this.isEdit = false;
        asset.isEdit = false;
      }
    }
    console.log("saving file location Id");
    console.log(asset.fileLocationId);
    var fileLocationId = ''
    if (asset.fileLocationId == undefined || asset.fileLocationId == null || asset.fileLocationId == '') {
      fileLocationId = UUID.UUID(); 
    } else {
      fileLocationId = asset.fileLocationId;
    }
    var fileDTO = {
      files: asset.files,
      fileLocationId: fileLocationId
    }
    this.assetService.uploadFiles(fileDTO);
    asset.fileLocationId = fileLocationId;

    this.assetService.save(asset).then((data) => {
      var assetId = data.id;
      this.router.navigate(['administration/assets'], { queryParams: { id: assetId } });
    });
  }

  saveNonNumberedAsset(asset) {
    if (this.id != undefined) {
      asset.id = this.id;
      asset.isEdit = true;
    }

    this.nonNumberedAssetService.save(asset).then((data) => {
      this.router.navigate(['administration/assets']);
    });
  }

}
