import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    
    //const isApiUrl = request.url.indexOf("api") > 0;
    //if (isApiUrl) {
    //  const req = request.clone({ url: "http://localhost:5001" + request.url });
    //  return next.handle(req);
    //}

    return next.handle(request);
  }
}
